import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import chunk from 'lodash.chunk'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import SliderUI from 'react-slick'
import styled from 'styled-components'
import 'slick-carousel/slick/slick.css'

import { UnlockingCard } from './UnlockingCard'

import { CarouselControlButtons } from '~components'
import { ROUNDS } from '~config'
import { MediaQueryBreakpoint } from '~enums'
import { Round } from '~interfaces'
import { useStaked } from '~state'
import { Headline3 } from '~ui'

const Col = styled(Bs.Col)<{ $margin?: boolean }>`
  margin-bottom: ${({ $margin }) => ($margin ? '30px' : '0')};

  padding: 0 15px !important;
`

const Row = styled(Bs.Row)`
  margin: 0 -15px !important;
  display: flex !important;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`
const UNLOCKING_SCHEDULE_ROUNDS = ROUNDS as Round[]

const UNLOCKING_MONTHS_ROUND_A = 100 / UNLOCKING_SCHEDULE_ROUNDS[0].monthlyUnlockPercent
const UNLOCKING_MONTHS_ROUND_B = 100 / UNLOCKING_SCHEDULE_ROUNDS[2].monthlyUnlockPercent

const getMonthlyUnlockAmount = ({
  month,
  stakedRoundA,
  stakedRoundB
}: {
  month: number
  stakedRoundA: BigNumber
  stakedRoundB: BigNumber
}): BigNumber => {
  const roundAUnlock = stakedRoundA
    .multipliedBy(UNLOCKING_SCHEDULE_ROUNDS[0].monthlyUnlockPercent)
    .div(100)
  if (!month || month > UNLOCKING_MONTHS_ROUND_B) return roundAUnlock

  const roundBUnlock = stakedRoundB
    .multipliedBy(UNLOCKING_SCHEDULE_ROUNDS[2].monthlyUnlockPercent)
    .div(100)
  return roundAUnlock.plus(roundBUnlock)
}

export const UnlockingSchedule = () => {
  const slider = useRef<SliderUI>(null)
  const { t } = useTranslation('unlockingSchedule')
  const { roundA, roundB } = useStaked()
  const isMd = useMediaQuery({ maxWidth: MediaQueryBreakpoint.md })

  const CHUNK_LENGTH = isMd ? 1 : 6

  return (
    <div>
      <Header>
        <Headline3>{t('LTT Unlocking Schedule')}</Headline3>
        <CarouselControlButtons
          onPrevClick={() => slider.current?.slickPrev()}
          onNextClick={() => slider.current?.slickNext()}
        />
      </Header>
      <SliderUI ref={slider} arrows={false}>
        {chunk(new Array(UNLOCKING_MONTHS_ROUND_A).fill(undefined), CHUNK_LENGTH).map(
          (chunk, i) => (
            <div>
              <Row key={i}>
                {chunk.map((_, j) => (
                  <Col
                    $margin={!isMd ? (j + 1) % 6 < 5 && (j + 1) % 6 > 0 : false}
                    xs={12}
                    md={isMd ? 12 : 6}
                    key={j}
                  >
                    <UnlockingCard
                      month={i * CHUNK_LENGTH + j}
                      unlockAmount={getMonthlyUnlockAmount({
                        month: i * CHUNK_LENGTH + j,
                        stakedRoundA: roundA,
                        stakedRoundB: roundB
                      })}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          )
        )}
      </SliderUI>
    </div>
  )
}
