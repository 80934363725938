import { srcList } from './images'

import { Banner, BannerProps } from '~ui'

export const UtilityBanner = (props: Partial<BannerProps>) => {
  return (
    <Banner
      {...props}
      srcList={srcList}
      href="https://blog.localtrade.cc/meet-updated-localtrade-roadmap-token-utility-b6bf121a8327"
    />
  )
}
