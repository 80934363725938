import { TFunction } from 'react-i18next'

import * as images from './images'

import { Advantages } from '~interfaces'

export const getAdvantages = (t: TFunction<'advantages'>): Advantages[] => [
  {
    title: t('Start with just $10!'),
    description: t(
      'LocalTrade makes DeFi opportunities accessible for any retail investor. Tap into some of the most prosperous DeFi projects, starting with only $10.'
    ),
    image: {
      normal: images.start,
      small: images.startSmall
    }
  },
  {
    title: t('Diversify Investments. Maximize APY.'),
    description: t(
      'Choose from our risk-adjusted strategies and start immediately earning yields across a variety of DeFi products: invest in tokenized stocks, farm yield across DEX’s and participate in the hottest token sales.',
      { nsSeparator: false }
    ),
    image: { normal: images.diversify, small: images.diversifySmall }
  },
  {
    title: t('Empowering DAO Community'),
    description: t(
      'Shape the future of DeFi with us. With DAO Sharing Economy, we aim to empower the community by giving them access to a long-lasting and powerful income source, straight from the core of our platform.'
    ),
    image: { normal: images.dao, small: images.daoSmall }
  },
  {
    title: t('Own the keys. Own the funds.'),
    description: t(
      'Connect your own wallet and be in full control of your funds. There are no central intermediaries that hold your private keys. Fail-proof smart contracts secure your operations at all times when using LocalTrade DeFi Lab.'
    ),
    image: { normal: images.own, small: images.ownSmall }
  },
  {
    title: t('Synergetic Sharing Economy'),
    description: t(
      'Being a community-centric platform, LocalTrade has created the infinitely profitable DAO Sharing Economy. Stake LTT tokens to enhance your APY across DeFi lab and to receive additional rewards from your referrals.'
    ),
    image: { normal: images.economy, small: images.economySmall }
  },
  {
    title: t('Institutional-grade Research'),
    description: t(
      'Before featuring any project on the DeFi Lab, we conduct strict scrutiny, evaluating the team and auditing smart-contracts in order to determine potential risks and thus prevent users from investing in deliberately fraudulent projects.'
    ),
    image: { normal: images.research, small: images.researchSmall }
  },
  {
    title: t('DeFi Investments Made Simple'),
    description: t(
      'There’s no need to be a blockchain expert to use DeFi Lab. Connect your own crypto wallet or use a fiat bank card to swiftly invest into most reliable DeFi projects via our unified easy-to-use interface.'
    ),
    image: { normal: images.defi, small: images.defiSmall }
  },
  {
    title: t('Hyper-optimized Smart Contracts, Audited by Experts'),
    description: t(
      'Extreme security of our smart contracts is ensured through rigorous internal scrutiny and thorough audits performed by expert security firms.'
    ),
    image: { normal: images.contracts, small: images.contractsSmall }
  }
]
