import * as Bs from '@bootstrap-styled/v4'
import React, { PropsWithChildren, ReactNode, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { NavItem } from './NavItem'
import { Toggle } from './Toggle'

import { routes } from '~config'
import { MediaQueryBreakpoint, Ticker } from '~enums'
import { BodyS, Icon, BodyM } from '~ui'
import { mediaQuery } from '~utils'

const DropdownToggle = styled(Bs.DropdownToggle)`
  padding: 0 !important;
  margin: 0 40px 0 0 !important;
  line-height: 0 !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center;
  font-weight: normal !important;

  ${mediaQuery.lessThan('lg')`
    height: auto;
    line-height: 24px !important;
  `}

  &::after {
    margin-left: 10px !important;
    transition: transform ease-in-out 0.2s;
  }

  ${({ active }) =>
    active &&
    `
    &::after {
      transform: rotate(180deg);
    }
  `}
`

const ProductsIcon = styled((props) => <Icon {...props} name="products" />)`
  padding: 0;
`

const DropdownMenu = styled(Bs.DropdownMenu)`
  top: 90% !important;
  left: unset !important;
  min-width: unset !important;
  border-radius: 24px !important;
  border: 2px solid ${({ theme }) => theme.colors.secondaryShade1} !important;
  background: ${({ theme }) => theme.colors.secondaryShade1} !important;
  padding: 0 !important;
  backdrop-filter: blur(50px);

  ${mediaQuery.lessThan('lg')`
    top: 80% !important;
  `}
`

const DropdownItem = styled(Bs.DropdownItem)`
  padding: 20px !important;
  font-size: 14px;
  text-align: center !important;
  margin: 0 !important;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white} !important;
  &:active {
    background: none !important;
  }

  &:first-child {
    border-radius: 24px 24px 0 0 !important;
    padding-top: 20px !important;
    &:hover {
      padding-top: 20px !important;
      border-top: none !important;
    }
  }

  &:last-child {
    border-radius: 0 0 24px 24px !important;
    padding-bottom: 20px !important;
    &:hover {
      padding-bottom: 20px !important;
      border-bottom: none !important;
    }
  }

  &:hover {
    padding: 18px 20px !important;
    border-top: 2px solid ${({ theme }) => theme.colors.secondaryShade1} !important;
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondaryShade1} !important;
    background: ${({ theme }) => theme.colors.secondaryShade1} !important;
  }
`

const ProductIcon = styled(Icon)`
  padding: 4px 13px 4px 2px;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
`

const Desc = styled(BodyS)`
  color: ${({ theme }) => theme.colors.tertiaryShade5};
  ${mediaQuery.lessThan('md')`
    color: ${({ theme }) => theme.colors.white};
  `}
`

const ProductsBlock = ({
  children,
  className,
  caret,
  navToggle
}: PropsWithChildren<{
  className?: string
  caret?: boolean
  navToggle: () => void
}>) => {
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation(['navbar', 'products'])

  const history = useHistory()
  const isLg = useMediaQuery({ minWidth: MediaQueryBreakpoint.lg })

  const items: Array<{ children: ReactNode; redirect?: string; route?: string }> = [
    {
      redirect: 'https://localtrade.cc/',
      children: (
        <div className="d-flex">
          <ProductIcon name="cex" />
          <div className="d-flex flex-column justify-content-between align-items-baseline">
            <BodyM $bold>{t('LocalTrade CEX')}</BodyM>
            <Desc>{t('Spot trading with advanced interface')}</Desc>
          </div>
        </div>
      )
    },
    {
      route: routes.staking,
      children: (
        <div className="d-flex">
          <ProductIcon name="launchPool" />
          <div className="d-flex flex-column justify-content-between align-items-baseline">
            <BodyM $bold>{t('products:Farming Vault')}</BodyM>
            <Desc>{t('products:Stake LTT and other crypto to earn rewards')}</Desc>
          </div>
        </div>
      )
    },
    {
      route: routes.sale,
      children: (
        <div className="d-flex">
          <ProductIcon name="ltt" />
          <div className="d-flex flex-column justify-content-between align-items-baseline">
            <BodyM $bold>{t('LocalTrade Token')}</BodyM>
            <Desc>
              <Trans
                t={t}
                i18nKey="Claim your tokens from LTT tokensale"
                values={{ ticker: Ticker.LTT }}
              >
                <strong />
              </Trans>
              !
            </Desc>
          </div>
        </div>
      )
    },
    {
      route: routes.marketplace,
      children: (
        <div className="d-flex">
          <ProductIcon name="neobroker" />
          <div className="d-flex flex-column justify-content-between align-items-baseline">
            <BodyM $bold>{t('NeoBroker Smart Fund')}</BodyM>
            <Desc>{t('DeFi way to invest in private equity companies')}</Desc>
          </div>
        </div>
      )
    }
  ]
  return isLg ? (
    <Bs.Dropdown
      className={`${className} d-flex justify-content-lg-center`}
      onClick={(e: any) => {
        e.stopPropagation()
      }}
      isOpen={isOpen}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      toggle={() => setIsOpen(!isOpen)}
    >
      <DropdownToggle active={isOpen} caret={caret}>
        {children}
      </DropdownToggle>
      <DropdownMenu>
        {items.map((el, i) => (
          <DropdownItem
            onClick={() => {
              if (el.redirect) window.open(el.redirect, '_blank')
              else if (el.route) history.push(el.route)
              setIsOpen(false)
              navToggle()
            }}
            key={i}
          >
            {el.children}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Bs.Dropdown>
  ) : (
    <>
      <NavItem>
        <Toggle active={isOpen} onClick={() => setIsOpen(!isOpen)}>
          {children}
        </Toggle>
      </NavItem>
      <Bs.Collapse className="w-100" isOpen={isOpen}>
        {items.map((el, i) => (
          <NavItem nested key={i}>
            <div
              onClick={() => {
                if (el.redirect) window.open(el.redirect, '_blank')
                else if (el.route) history.push(el.route)
                setIsOpen(false)
                navToggle()
              }}
            >
              {el.children}
            </div>
          </NavItem>
        ))}
      </Bs.Collapse>
    </>
  )
}

export const ProductsButton = styled(
  ({ className, navToggle }: { className?: string; navToggle: () => void }) => {
    const { t } = useTranslation('navbar')

    const isLg = useMediaQuery({ minWidth: MediaQueryBreakpoint.lg })

    return (
      <ProductsBlock className={className} caret navToggle={navToggle}>
        {isLg ? <ProductsIcon /> : <div>{t('LocalTrade Ecosystem')}</div>}
      </ProductsBlock>
    )
  }
)`
  text-transform: uppercase;
  padding: 8px 10px !important;
  justify-content: space-between;
  height: 100%;

  ${mediaQuery.lessThan('lg')`
    display: block !important;
    padding: 0 !important;
    font-weight: normal !important;
  `}
`
