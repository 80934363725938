import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ConnectButton as ConnectButtonUI } from '../ConnectButton'

import { Ticker } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { useAccountModal } from '~state'
import { Card, Headline1, Headline4 } from '~ui'

const TOTAL_RAISED_AMOUNT = '1 914 733'

const Title = styled(Headline4)`
  display: inline;
  color: ${({ theme }) => theme.colors.secondary};
`

const Confetti = styled.span.attrs({ children: '🎉🎉🎉' })`
  font-size: 26px;
`

const ConnectButton = styled(ConnectButtonUI)`
  width: 100%;
  margin-top: 20px;
`

export const TotalRaised = () => {
  const { t } = useTranslation('sale')
  const { account } = useActiveWeb3React()
  const [, { open: openAccountModal }] = useAccountModal()
  return (
    <Card withBorder>
      <div>
        <Title className="mr-2">{t('Total Raised on Tokensale')}</Title>
        <Confetti />
      </div>
      <Headline1>
        {TOTAL_RAISED_AMOUNT} {Ticker.BUSD}
      </Headline1>
      {!account && <ConnectButton onClick={openAccountModal} />}
    </Card>
  )
}
