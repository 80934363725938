import BigNumber from 'bignumber.js'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'

import { Ticker } from '~enums'
import { BodyS, BodyL, Card as CardUi, Headline3, BodyM } from '~ui'
import { NumberFormatter } from '~utils'

const Card = styled(CardUi).attrs({ withBorder: true })`
  border-radius: 12px !important;
  padding: 20px;
`

const Badge = styled.div`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.secondaryShade1};
  padding: 16px 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const SecondaryText = styled(BodyS)`
  color: ${({ theme }) => theme.colors.secondary};
`

const Month = styled(Headline3)`
  margin-right: 20px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const UnlockingCard = ({
  month,
  unlockAmount
}: {
  month: number
  unlockAmount: BigNumber
}) => {
  const { t } = useTranslation('unlockingSchedule')

  return (
    <Card>
      <Badge>
        <Trans
          t={t}
          i18nKey="{month} month {round}"
          values={{
            month: (month + 1).toString().length === 1 ? '0' + (month + 1).toString() : month + 1,
            round: t('after Round B')
          }}
        >
          <Month />
          <BodyM />
        </Trans>
      </Badge>
      <Wrapper>
        <SecondaryText className="d-block">{t('Unlocking Amount')}</SecondaryText>
        <BodyL $bold>
          {NumberFormatter.formatCustomDecimal(unlockAmount, 9, 2)} {Ticker.LTT}
        </BodyL>
      </Wrapper>
    </Card>
  )
}
