import { srcList } from './images'

import { Banner, BannerProps } from '~ui'

export const RoadmapBanner = (props: Partial<BannerProps>) => {
  return (
    <Banner
      {...props}
      srcList={srcList}
      href="https://storage.googleapis.com/localtrade-defi-lab-statics/gitbook/localtrade_roadmap.pdf"
    />
  )
}
