import { srcList } from './images'

import { Banner, BannerProps } from '~ui'

export const WalletBanner = (props: Partial<BannerProps>) => {
  return (
    <Banner
      {...props}
      srcList={srcList}
      href="https://blog.localtrade.cc/localtrade-defi-wallet-updates-plan-e65949121e1d"
    />
  )
}
