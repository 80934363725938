import * as Bs from '@bootstrap-styled/v4'
import styled from 'styled-components'

import { TokenSaleRounds } from './TokenSaleRounds'
import { UnlockingSchedule } from './UnlockingSchedule'

import { mediaQuery } from '~utils'

const Row = styled(Bs.Row)`
  ${mediaQuery.lessThan('md')`
    gap: 20px;
      `}
`

export const TokenSaleRoundsAndUnlockingSchedule = () => {
  return (
    <Row>
      <Bs.Col lg={5}>
        <TokenSaleRounds />
      </Bs.Col>
      <Bs.Col lg={7}>
        <UnlockingSchedule />
      </Bs.Col>
    </Row>
  )
}
