import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React, { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import { Amount, Button, Label, SmartMargin, MarginWrapper } from '../../../components'

import { StakeModal } from './StakeModal'

import { MediaQueryBreakpoint, Ticker, TickerBEP20, TickerStaking } from '~enums'
import { useActiveWeb3React } from '~hooks'
import { Token, VaultMetadata } from '~interfaces'
import { TickerInputGroup, TickerInputGroupAddon } from '~ticker'
import { FormGroup, Icon, Input, BodyS, SecondaryButton as SecondaryButtonUI } from '~ui'
import { NumberFormatter, useToggle } from '~utils'

const WAValidator = require('multicoin-address-validator')

const SecondaryButton = styled(SecondaryButtonUI)`
  padding: 10px !important;
`

const SetInviter = styled(BodyS).attrs({ $bold: true })`
  display: flex;
  gap: 5px;
  align-items: center;
  width: auto;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export type AddStakingProps = {
  stakeValue: BigNumber
  staking: boolean
  approving: boolean
  spendBalance: BigNumber
  bnbBalance: BigNumber
  stakeFee?: BigNumber
  executeStake: () => void
  onSetSpend: (value: string) => void
  onSetTicker: (ticker: TickerStaking) => void
  spendValue: string
  spendTicker: TickerStaking
  onInviterChanged: (inviter: string) => void
  vault: VaultMetadata
  inviter: string
  color?: string
  isInviterMutable: boolean
  back?: () => void
  validate?: () => Error | undefined
  validateModal?: () => Error | undefined
  estEarn: string
  approve: (ticker: TickerBEP20) => void
  allowances: Partial<Record<TickerBEP20, BigNumber>>
  stakeFromTokens: Token[]
  colorShade?: string
  averagePrice: BigNumber | undefined
  stakeModalChildren?: ReactNode
}

const WithColor = styled.span<{ color?: string }>`
  color: ${({ theme, color }) => color ?? theme.colors.primary};
`

export const AddStaking = ({
  stakeValue,
  onSetSpend,
  onSetTicker,
  back,
  onInviterChanged,
  vault,
  spendBalance,
  bnbBalance,
  inviter,
  isInviterMutable,
  stakeFee,
  executeStake,
  staking,
  estEarn,
  validate,
  validateModal,
  spendValue,
  spendTicker,
  approve,
  allowances,
  approving,
  stakeFromTokens,
  color,
  colorShade,
  averagePrice,
  stakeModalChildren
}: AddStakingProps) => {
  const { t } = useTranslation(['vault', 'common', 'referrals'])
  const { account } = useActiveWeb3React()
  const [inviterEdit, inviterEditToggle] = useToggle(false)
  const isLg = useMediaQuery({ maxWidth: MediaQueryBreakpoint.lg })
  const [isOpen, setOpen] = useState(false)

  const getButtonProps = () => {
    if (!stakeValue) {
      return {
        children: [t('Add {ticker} to Pool', { ticker: spendTicker })],
        disabled: true
      }
    }

    if (stakeFee && bnbBalance.lt(stakeFee)) {
      return {
        disabled: true,
        children: [t('common:Not enough {ticker} to pay the fee', { ticker: Ticker.BNB })]
      }
    }
    if (spendBalance.lt(NumberFormatter.fromDecimal(spendValue || '0', spendTicker)))
      return { disabled: true, children: [t('common:Insufficient balance')] }
    if (inviter && !WAValidator.validate(inviter, 'ETH'))
      return { disabled: true, children: [t('referrals:Invalid inviter')] }

    if (inviter && inviter === account) {
      return { disabled: true, children: [t('referrals:Inviter is the same as account')] }
    }

    if (
      spendTicker !== Ticker.BNB &&
      (!allowances[spendTicker] || allowances[spendTicker]?.lt(spendBalance))
    ) {
      return {
        children: [t('common:Allow')],
        loading: approving,
        onClick: () => approve(spendTicker)
      }
    }

    if (!stakeFee) {
      return {
        disabled: true,
        children: [t('Add {ticker} to Pool', { ticker: spendTicker })]
      }
    }

    const possibleError = validate?.()

    if (possibleError) {
      return {
        children: [possibleError.message],
        disabled: true
      }
    }

    return {
      children: [t('Add {ticker} to Pool', { ticker: spendTicker })]
    }
  }

  return (
    <Bs.Form>
      <FormGroup>
        <div className="d-flex justify-content-between">
          <Amount>{t('I want to add')}:</Amount>
          <div className="d-flex align-items-center">
            <Label>{t('common:Balance')}:</Label>{' '}
            <Amount
              className="cursor-pointer"
              onClick={() => onSetSpend(NumberFormatter.formatDecimal(spendBalance, spendTicker))}
            >
              {NumberFormatter.formatDecimal(spendBalance, spendTicker)} {spendTicker}
            </Amount>
          </div>
        </div>
        <SmartMargin top>
          <TickerInputGroup>
            <Input
              type="number"
              onKeyDown={(e: any) => {
                if (
                  e.key === '-' ||
                  (e.key === 'ArrowDown' && (e.target.value === '0' || e.target.value === ''))
                )
                  e.preventDefault()
              }}
              onWheel={(e: any) => {
                e.target.blur()
              }}
              onChange={(e: any) => {
                if (!NumberFormatter.isValidFormatDecimals(e.target.value, vault.stakeTicker))
                  return
                onSetSpend(e.target.value)
              }}
              value={spendValue}
            />
            <TickerInputGroupAddon
              activeTicker={spendTicker}
              onChange={(option) => onSetTicker(option!.value as TickerStaking)}
              tokens={stakeFromTokens}
              isDisabled={!(stakeFromTokens.length - 1)}
            />
          </TickerInputGroup>
        </SmartMargin>
      </FormGroup>
      {spendTicker !== vault.stakeTicker && (
        <SmartMargin top>
          <div className="d-flex align-items-center">
            <Label>{t('Value to stake')}:</Label>
            <Amount>
              {NumberFormatter.formatDecimal(stakeValue, Ticker.LTT)} {Ticker.LTT}
            </Amount>
          </div>
        </SmartMargin>
      )}
      <SmartMargin top>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <Label>{t('You could earn')}:</Label>
            <Amount>
              <WithColor color={color}>{estEarn}</WithColor> {vault.rewardTicker}
            </Amount>
          </div>
          <SetInviter onClick={inviterEditToggle}>
            {t('referrals:Set inviter')}{' '}
            <Icon name="arrow" width={15} height={15} rotate={inviterEdit ? 90 : 270} />
          </SetInviter>
        </div>
      </SmartMargin>
      {stakeFee && (
        <SmartMargin top>
          <div className="d-flex align-items-center">
            <Label>{t('common:Network Fee')}:</Label>
            <Amount>
              {NumberFormatter.formatDecimal(stakeFee, Ticker.BNB)} {Ticker.BNB}
            </Amount>
          </div>
        </SmartMargin>
      )}
      {inviterEdit && (
        <MarginWrapper>
          <FormGroup>
            <div>
              <Amount>{t('referrals:Set inviter')}:</Amount>
            </div>
            <SmartMargin top>
              <Input
                disabled={!isInviterMutable}
                onChange={(e: any) => onInviterChanged(e.target.value)}
                value={inviter}
              />
            </SmartMargin>
          </FormGroup>
        </MarginWrapper>
      )}
      <MarginWrapper>
        <Bs.Row noGutters>
          {back && (
            <Bs.Col xs={12} lg={6}>
              <SmartMargin right>
                <SecondaryButton wide outline onClick={back}>
                  ← {t('common:Back')}
                </SecondaryButton>
              </SmartMargin>
            </Bs.Col>
          )}
          <Bs.Col xs={12} lg={6}>
            <SmartMargin top={isLg} left>
              <Button
                onClick={() => setOpen(true)}
                backgroundColor={colorShade}
                loading={staking}
                hoverBackgroundColor={color}
                {...getButtonProps()}
              />
            </SmartMargin>
          </Bs.Col>
        </Bs.Row>
      </MarginWrapper>
      <StakeModal
        validate={validateModal}
        isOpen={isOpen}
        close={() => setOpen(false)}
        executeStake={executeStake}
        color={color}
        colorShade={colorShade}
        stakeTicker={vault.stakeTicker}
        stakeValue={stakeValue}
        spendValue={spendValue}
        spendTicker={spendTicker}
        stakeFee={stakeFee}
        staking={staking}
        averagePrice={averagePrice}
        children={stakeModalChildren}
      />
    </Bs.Form>
  )
}
