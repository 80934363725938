import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Headline4 } from '~ui'

const Month = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.tertiaryShade3};
`

export const DateCard = styled(({ date, className }: { date: Date; className?: string }) => {
  const { i18n } = useTranslation()
  const [month, day] = date
    .toLocaleDateString(i18n.language, { month: 'short', day: 'numeric' })
    .split(' ')

  return (
    <div className={className}>
      <Month className="d-flex justify-content-center align-items-center">{month}</Month>
      <div className="d-flex justify-content-center align-items-center flex-grow-1">
        <Headline4>{day}</Headline4>
      </div>
    </div>
  )
})`
  height: 80px;
  width: 80px;
  background-color: ${({ theme }) => theme.colors.tertiaryShade4};
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`
