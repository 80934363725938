import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Headline4, BodyL } from '~ui'
import { mediaQuery } from '~utils'

const Card = styled.div`
  text-transform: uppercase;
  padding: 20px;
  text-align: center;
  border: 2px solid ${({ theme }) => theme.colors.secondaryShade1};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  ${mediaQuery.greaterThan('md')`
         padding: 20px;
    `}
`

export const DateCard = ({ date }: { date: Date }) => {
  const { i18n } = useTranslation()
  const [month, year] = date
    .toLocaleDateString(i18n.language, { month: 'short', year: 'numeric' })
    .split(' ')

  return (
    <Card>
      <Headline4>{month}</Headline4>
      <BodyL>{year}</BodyL>
    </Card>
  )
}
