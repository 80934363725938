import React from 'react'
import { Helmet } from 'react-helmet'

import { Container } from '~components'
import {
  RoundsWithBanner,
  TotalRaised,
  TokenSaleRoundsAndUnlockingSchedule
} from '~components/sale'
import { BalanceAndReferralsTree } from '~components/staking'

export const SalePage = () => {
  return (
    <>
      <Helmet>
        <title>LocalTrade DeFi Lab | Token</title>
        <meta
          name="description"
          content={
            'LocalTrade Token is a BEP-20 standard token that powers up the whole LocalTrade ecosystem.' +
            'Claim your tokens from Token Sale and explore LTT financial metrics.'
          }
        />
      </Helmet>
      <Container>
        <TotalRaised />
      </Container>
      <Container>
        <RoundsWithBanner />
      </Container>
      <Container>
        <BalanceAndReferralsTree />
      </Container>
      <Container>
        <TokenSaleRoundsAndUnlockingSchedule />
      </Container>
    </>
  )
}
