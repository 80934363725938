import * as Bs from '@bootstrap-styled/v4'
import React from 'react'
import styled from 'styled-components'

import { FinishedRound, IdoRound } from './round'

import { BannersCarousel } from '~components'
import { ROUNDS } from '~config'
import { Round, IdoRound as IIdoRound } from '~interfaces'
import { mediaQuery } from '~utils'

const Col = styled(Bs.Col)`
  margin-bottom: 40px;
  ${mediaQuery.lessThan('lg')`
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    `}
`

export const RoundsWithBanner = () => (
  <Bs.Row>
    <Col className="order-1 order-md-2 order-xl-1" sm={12} md={6} xl={4}>
      <FinishedRound {...(ROUNDS[0] as Round)} />
    </Col>
    <Col className="order-2 order-md-1 order-xl-2" sm={12} md={6} xl={4}>
      <FinishedRound {...(ROUNDS[2] as Round)} />
    </Col>
    <Col className="order-3" sm={12} md={6} xl={4}>
      <IdoRound {...(ROUNDS[1] as IIdoRound)} />
    </Col>
    <Bs.Col className="order-6" xs={12}>
      <BannersCarousel />
    </Bs.Col>
  </Bs.Row>
)
