import React from 'react'
import styled from 'styled-components'

import { DateCard } from './DateCard'

import { Icon } from '~ui'
import { mediaQuery } from '~utils'

const Arrows = styled(({ className }) => {
  return (
    <div className={className}>
      <Icon name="squareArrow" />
      <Icon name="squareArrow" />
      <Icon name="squareArrow" />
      <Icon name="squareArrow" />
    </div>
  )
})`
  svg {
    flex: none;
  }
  overflow: hidden;
  height: 22px;
  ${mediaQuery.lessThan('xs')`
        max-width: 75px;
    `}
`

export const DateCards = styled(
  ({ startDate, endDate, className }: { startDate: Date; endDate: Date; className?: string }) => {
    return (
      <div className={`d-flex justify-content-between align-items-center ${className}`}>
        <DateCard date={startDate} />
        <Arrows />
        <DateCard date={endDate} />
      </div>
    )
  }
)`
  margin-bottom: 20px;
`
