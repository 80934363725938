import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Round } from './Round'

import { Card } from '~components'
import { ROUNDS } from '~config'
import { Round as IRound } from '~interfaces'
import { Headline3 } from '~ui'

const CardWithGap = styled(Card).attrs({ withBorder: true })`
  gap: 30px;
`

export const TokenSaleRounds = () => {
  const { t } = useTranslation('rounds')

  return (
    <CardWithGap>
      <Headline3>{t('Token Sale Rounds')}</Headline3>
      {ROUNDS.map((r, i) => (
        <Round key={i} isIDO={i === 1} {...(r as IRound)} />
      ))}
    </CardWithGap>
  )
}
