import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DateCards } from './DateCards'

import { Ticker } from '~enums'
import { Round as IRound } from '~interfaces'
import { BodyM, Card as TransparentCard, Headline4 } from '~ui'
import { NumberFormatter } from '~utils'

const formatBusdValue = (value: BigNumber) =>
  NumberFormatter.toDecimal(value, Ticker.BUSD).toFixed(2, BigNumber.ROUND_DOWN)

const Card = styled(TransparentCard)`
  padding: 0;
`

const CardHeader = styled(Bs.CardHeader)`
  background-color: transparent !important;
  padding: 30px !important;
  border-color: ${({ theme }) => theme.colors.secondaryShade1} !important;
`
const Title = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
`

const MarginWrapper = styled.div`
  margin-bottom: 16px;
`

export const FinishedRound = ({ name, startDate, endDate, tokenPrice, amount }: IRound) => {
  const { t } = useTranslation('rounds')

  return (
    <Card withBorder>
      <CardHeader>
        <Bs.CardTitle as={Headline4} className="mb-0 d-flex justify-content-between">
          {t(`names.${name}`)}
        </Bs.CardTitle>
      </CardHeader>
      <Bs.CardBlock>
        <DateCards startDate={startDate} endDate={endDate} />
        <MarginWrapper className="d-flex justify-content-between">
          <Title>{t('Token Price')}:</Title>
          <BodyM>
            {Array.isArray(tokenPrice) ? (
              <>
                {formatBusdValue(tokenPrice[0])} - {formatBusdValue(tokenPrice[1])} {Ticker.BUSD}
              </>
            ) : (
              <>
                {formatBusdValue(tokenPrice)} {Ticker.BUSD}
              </>
            )}
          </BodyM>
        </MarginWrapper>
        <div className="d-flex justify-content-between">
          <Title>{t('Amount sold')}:</Title>
          <BodyM>
            {NumberFormatter.toDecimal(amount.value, Ticker.LTT).toFixed(0, BigNumber.ROUND_DOWN)}{' '}
            {Ticker.LTT}
          </BodyM>
        </div>
      </Bs.CardBlock>
    </Card>
  )
}
