import * as Bs from '@bootstrap-styled/v4'
import BigNumber from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import kwikstarter from './images/kwikstarter.png'
import nftb from './images/nftb.svg'
import purefi from './images/purefi.svg'

import { Card as CardUi } from '~components'
import { Ticker } from '~enums'
import { IdoRound as IIdoRound } from '~interfaces'
import { BodyM, Headline4 } from '~ui'
import { mediaQuery, NumberFormatter } from '~utils'

const formatBusdValue = (value: BigNumber) =>
  NumberFormatter.toDecimal(value, Ticker.BUSD).toFixed(2, BigNumber.ROUND_DOWN)

const Card = styled(CardUi)`
  padding: 0;
`

const Title = styled(BodyM)`
  color: ${({ theme }) => theme.colors.secondary};
`

const CardHeader = styled(Bs.CardHeader)`
  background-color: transparent !important;
  padding: 30px !important;
  border-color: ${({ theme }) => theme.colors.secondaryShade1} !important ;
`

const Img = styled.img`
  cursor: pointer;
  ${mediaQuery.lessThan('md')`
    max-width: 150px;
  `}
`

const FlexBreak = styled.div`
  flex-basis: 100%;
`

const MarginWrapper = styled.div`
  margin-bottom: 16px;
`

export const IdoRound = ({ name, endDate, tokenPrice, amount, logo, link }: IIdoRound) => {
  const { t } = useTranslation('rounds')

  return (
    <Card withBorder>
      <CardHeader>
        <Bs.CardTitle as={Headline4} className="mb-0 d-flex justify-content-between">
          <div>{t(`names.${name}`)}</div>
        </Bs.CardTitle>
      </CardHeader>
      <Bs.CardBlock>
        <div className="d-flex justify-content-center flex-wrap align-items-center mb-1">
          <a className="mr-3" href="https://purefi.io/" target="_blank">
            <Img src={purefi} alt="PureFi" />
          </a>
          <a href="https://nftb.io" target="_blank">
            <Img src={nftb} alt="NFTB" />
          </a>
          <FlexBreak />
          <a href="https://kwikstarter.org" target="_blank">
            <Img src={kwikstarter} alt="kwikstarter" />
          </a>
        </div>
        <MarginWrapper className="d-flex justify-content-between">
          <Title>{t('Token Price')}:</Title>
          <BodyM>
            {Array.isArray(tokenPrice) ? (
              <>
                {formatBusdValue(tokenPrice[0])} - {formatBusdValue(tokenPrice[1])} {Ticker.BUSD}
              </>
            ) : (
              <>
                {formatBusdValue(tokenPrice)} {Ticker.BUSD}
              </>
            )}
          </BodyM>
        </MarginWrapper>
        <div className="d-flex justify-content-between">
          <Title>{t('Amount sold')}:</Title>
          <BodyM>
            {NumberFormatter.toDecimal(amount.value, Ticker.LTT).toFixed(0, BigNumber.ROUND_DOWN)}{' '}
            {Ticker.LTT}
          </BodyM>
        </div>
      </Bs.CardBlock>
    </Card>
  )
}
