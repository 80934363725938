import { BigNumber } from 'bignumber.js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DateCard } from './DateCard'

import { Ticker } from '~enums'
import { Round as IRound } from '~interfaces'
import { BodyL as BodyLUi, BodyS as BodySUi, Card, Headline4, Icon } from '~ui'
import { mediaQuery, NumberFormatter } from '~utils'

const BodyL = styled(BodyLUi)`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.secondary};
  span {
    color: ${({ theme }) => theme.colors.secondaryShade2};
  }
`

const BodyS = styled(BodySUi)`
  color: ${({ theme }) => theme.colors.secondary};
  span {
    color: ${({ theme }) => theme.colors.secondaryShade2};
  }
`
const StyledIcon = styled(Icon)`
  margin-right: 10px;
`

const StyledCard = styled(Card)`
  padding: 20px;
  ${mediaQuery.greaterThan('md')`
        flex-direction: row !important;
          gap: 20px;
    `}
  justify-content: start;
  gap: 15px;
`

export const Round = ({
  amount,
  startDate,
  name,
  monthlyUnlockPercent,
  monthCliff,
  isIDO
}: Pick<IRound, 'amount' | 'startDate' | 'name' | 'monthlyUnlockPercent' | 'monthCliff'> & {
  isIDO?: boolean
}) => {
  const { t } = useTranslation('rounds')
  return (
    <StyledCard>
      <DateCard date={startDate} />
      <div>
        <Headline4>{t(`names.${name}`)}</Headline4>
        <BodyL>
          {NumberFormatter.toDecimal(amount.value, Ticker.LTT).toFixed(0, BigNumber.ROUND_DOWN)}{' '}
          {Ticker.LTT} = <span>{amount.percent}%</span>
        </BodyL>
        <div className="d-flex align-items-center">
          <StyledIcon name="locked" />
          {monthCliff && (
            <BodyS className="text-nowrap">
              <span>{monthCliff}</span> {t('month cliff')} {'→ '}
            </BodyS>
          )}
          <BodyS>
            <span>{monthlyUnlockPercent}%</span>
            {isIDO ? t('TGE, 80% invested over 5 months') : ` ${t('monthly unlock')}`}
          </BodyS>
        </div>
      </div>
    </StyledCard>
  )
}
